import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React from "react";
import useStyles from "../../../styles/components/Dialog/ConfirmLogout";

type DialogConfirmLogoutProps = {
  readonly t: TFunction;
  open: boolean;
  onClose: () => void;
  handleLogout: () => void;
};

const DialogConfirmLogout = (props: DialogConfirmLogoutProps) => {
  const { t, open, onClose, handleLogout } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <Box className={classes.dialogHeader}>
        <Typography variant="h2">{t("logout")}</Typography>
        <Close onClick={onClose} className={classes.closeIcon} />
      </Box>
      <Typography>{t("are-you-sure-want-to-logout")}</Typography>
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.cancelButton}
          disableElevation
          variant="outlined"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className={classes.orderButton}
          onClick={handleLogout}
        >
          {t("logout")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogConfirmLogout;
