import { Box } from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import useStyles from "../../styles/components/ImageSlider";

type ImageSliderProps = {
  listImage: string[];
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageSlider = (props: ImageSliderProps) => {
  const classes = useStyles(props);
  const { listImage } = props;
  const [indexImg, setIndexImg] = useState(0);

  return (
    <Box position="relative">
      <AutoPlaySwipeableViews
        index={indexImg}
        onChangeIndex={(index) => setIndexImg(index)}
      >
        {!listImage?.length ? (
          <img
            src="/img/placeholder-image.svg"
            alt="placeholder-banner"
            width="100%"
            className={classes.bannerImage}
          />
        ) : (
          listImage.map((res, index) => (
            <img
              src={res}
              key={index}
              width="100%"
              alt="banner-detail-hotel"
              className={classes.bannerImage}
            />
          ))
        )}
      </AutoPlaySwipeableViews>
      {listImage?.length > 1 && (
        <>
          <Box className={classes.paginationImg}>
            {listImage.slice(0, 20).map((res, index) => (
              <Box
                key={index}
                className={index === indexImg ? classes.dotActive : classes.dot}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ImageSlider;
