const PhoneIcon = (props) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.width || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7071 14.2071L20.3552 16.8552C20.7113 17.2113 20.7113 17.7887 20.3552 18.1448C18.43 20.07 15.3821 20.2866 13.204 18.653L11.6286 17.4714C9.88504 16.1638 8.33622 14.615 7.02857 12.8714L5.84701 11.296C4.21341 9.11788 4.43001 6.06999 6.35523 4.14477C6.71133 3.78867 7.28867 3.78867 7.64477 4.14477L10.2929 6.79289C10.6834 7.18342 10.6834 7.81658 10.2929 8.20711L9.27175 9.22825C9.10946 9.39054 9.06923 9.63846 9.17187 9.84373C10.3585 12.2171 12.2829 14.1415 14.6563 15.3281C14.8615 15.4308 15.1095 15.3905 15.2717 15.2283L16.2929 14.2071C16.6834 13.8166 17.3166 13.8166 17.7071 14.2071Z"
        stroke={props.color || process.env.PRIMARY_COLOR || "#222222"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PhoneIcon;
