import { Box, Dialog, Divider, Typography } from "@material-ui/core";
import { Close, NavigateNext } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React from "react";
import { i18n, Router } from "../../i18n";
import { LanguageIcon, LogoutIcon, YourBookingIcon } from "../../public/img";
import EmailPrefixInput from "../../public/img/EmailPrefixInput";
import PhoneIcon from "../../public/img/PhoneIcon";
import useStyles from "../../styles/components/DialogProfile";
import { DataLoginDto } from "../libs/guest";
import { GuestMembershipByGroupIdDto } from "../libs/membership";
import { TenantProfileDto } from "../libs/tenant";
import CardMembership from "./CardMembership";
import MenuItem from "./MenuItem";

type DialogProfileProps = {
  readonly t: TFunction;
  open: boolean;
  dataLogin: DataLoginDto["guest"];
  onClose: () => void;
  handleShowDialogLogout: () => void;
  handleShowDialogChangeLanguage: () => void;
  wallet: number;
  points: number;
  totalVoucher: number;
  membershipData?: GuestMembershipByGroupIdDto["data"];
  tenantProfile: TenantProfileDto;
};

const DialogProfile = (props: DialogProfileProps) => {
  const {
    t,
    onClose,
    open,
    dataLogin,
    wallet,
    points,
    totalVoucher,
    membershipData,
    tenantProfile,
    handleShowDialogLogout,
    handleShowDialogChangeLanguage,
  } = props;
  const classes = useStyles();
  const language = i18n.language;

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialogProfile}>
      <Box className={classes.dialogHeader}>
        <Typography variant="h2">{t("profile")}</Typography>
        <Close onClick={onClose} className={classes.closeIcon} />
      </Box>
      <Box className={classes.accountInfoWrapper}>
        <Typography className={classes.titleSection}>Account info</Typography>
        <Typography variant="h3" className={classes.userName}>
          {dataLogin.name}
        </Typography>
        <Box className={classes.dataWrapper}>
          <EmailPrefixInput strokeWidth="1.5" />
          <Typography>{dataLogin.email}</Typography>
        </Box>
        {dataLogin.phoneNumber && (
          <Box className={classes.dataWrapper}>
            <PhoneIcon />
            <Typography>+{dataLogin.phoneNumber}</Typography>
          </Box>
        )}
      </Box>
      <Box mt="8px" mb="24px">
        <CardMembership
          wallet={wallet}
          points={points}
          totalVoucher={totalVoucher}
          membershipData={membershipData}
          isLogin={true}
          tenantProfile={tenantProfile}
          t={t}
        />
      </Box>
      <Box>
        <Typography className={classes.titleSection}>Menu</Typography>
        <MenuItem
          text={t("your-booking")}
          icon={<YourBookingIcon />}
          suffix={<NavigateNext color="primary" />}
          onClick={() => Router.push("/orders")}
        />
        <Divider className={classes.dashedDivider} />
        <MenuItem
          text={t("change-language")}
          icon={<LanguageIcon />}
          suffix={
            <Typography className={classes.language}>
              {language === "id" ? "Indonesia" : "English"}
            </Typography>
          }
          onClick={handleShowDialogChangeLanguage}
        />
        <Divider className={classes.dashedDivider} />
        <MenuItem
          text={t("logout")}
          icon={<LogoutIcon />}
          onClick={handleShowDialogLogout}
        />
      </Box>
    </Dialog>
  );
};

export default DialogProfile;
