import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { TFunction } from "next-i18next";
import React from "react";
import { Router } from "../../i18n";
import useStyles from "../../styles/components/CardBanner";
import { BannerDto } from "../libs/banner";

type CardBannerProps = {
  readonly t: TFunction;
  banner: BannerDto;
};

const CardBanner = (props: CardBannerProps) => {
  const { t, banner } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardBanner}>
      <CardMedia
        component="img"
        alt="banner-img"
        height="240px"
        image={banner.image.url}
      />
      <CardContent className={classes.cardContent}>
        <Box className={classes.topContent}>
          <Typography variant="h3">{banner.title}</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: banner.description }}
            className={classes.bannerDescription}
          />
        </Box>
        <Typography
          className={classes.buttonReadMore}
          color="primary"
          onClick={() => Router.push(`/detail-banner?id=${banner.id}`)}
        >
          {t("read-more")}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardBanner;
