import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import _ from "lodash";
import { TFunction } from "next-i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { i18n, Router, withTranslation } from "../i18n";
import CardBanner from "../src/components/CardBanner";
import DialogChangeLanguage from "../src/components/Dialog/ChangeLanguage";
import DialogConfirmLogout from "../src/components/Dialog/ConfirmLogout";
import DialogAuth from "../src/components/DialogAuth";
import DialogProfile from "../src/components/DialogProfile";
import Footer from "../src/components/Footer";
import ImageSlider from "../src/components/ImageSlider";
import Loading from "../src/components/Loading";
import Navbar from "../src/components/NewNavbar";
import SelectCheckinCheckout from "../src/components/SelectCheckinCheckout";
import { BannerDto } from "../src/libs/banner";
import { CityDto } from "../src/libs/city";
import { DataLoginDto } from "../src/libs/guest";
import { GuestMembershipByGroupIdDto } from "../src/libs/membership";
import { TenantProfileDto } from "../src/libs/tenant";
import { getBanners } from "../src/services/banner";
import { getTenantProfile } from "../src/services/common";
import { getGuestMembershipByGroupId } from "../src/services/membership";
import { getTotalPoints } from "../src/services/points";
import { getListCity, getListHotels } from "../src/services/rooms";
import { getGuestVoucher } from "../src/services/voucher";
import { getGuestTotalBalances } from "../src/services/wallet";
import { getBuildDate } from "../src/utils/clearCache";
import nextDayDate from "../src/utils/nextDayDate";
import useStyles from "../styles/home";

type Props = {
  readonly t: TFunction;
  isLogin?: boolean;
};

interface DateCheckinType {
  in: Date | null;
  out: Date | null;
}

function Home(props: Props) {
  const classes = useStyles(props);
  const { t } = props;
  const cityId = Router.query.cityId as string;
  const [listCity, setListCity] = useState<CityDto[]>([]);
  const [listBanner, setListBanner] = useState<BannerDto[]>([]);
  const [openDialogAuth, setOpenDialogAuth] = useState(false);
  const [openDialogProfile, setOpenDialogProfile] = useState(false);
  const [openConfirmationLogout, setOpenConfirmationLogout] = useState(false);
  const [openDialogChangeLanguage, setOpenDialogChangeLanguage] =
    useState(false);
  const [dateCheckin, setDateCheckin] = useState<DateCheckinType>({
    in: new Date(),
    out: nextDayDate(new Date()),
  });
  const [isLogin, setIsLogin] = useState(false);
  const [dataLogin, setDataLogin] = useState<DataLoginDto["guest"]>(Object);
  const [wallet, setWallet] = useState<number>(0);
  const [points, setPoints] = useState<number>(0);
  const [totalVoucher, setTotalVoucher] = useState<number>(0);
  const [membershipData, setMembershipData] =
    useState<GuestMembershipByGroupIdDto["data"]>();
  const [tenantProfile, setTenantProfile] = useState<TenantProfileDto>(Object);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateLanguage, setIsUpdateLanguage] = useState(false);
  const targetRef = useRef<null | HTMLDivElement>(null);
  const [version, setVersion] = useState("");
  const [hotelImages, setHotelImages] = useState<string[]>([]);
  const certainHotel = process.env.CERTAIN_HOTEL?.split(",") as string[];

  useEffect(() => {
    const token = localStorage.getItem("token");
    const data_login = JSON.parse(localStorage.getItem("data_login") || "{}");
    const version = parseInt(localStorage.getItem("version") || "0");
    if (token) {
      setIsLogin(true);
    }
    if (data_login.guest) {
      setDataLogin(data_login?.guest);
    }
    if (version) {
      setVersion(getBuildDate(version));
    }
  }, []);

  const getConfigTenant = async () => {
    try {
      const response = await getTenantProfile();
      setTenantProfile(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (isLogin) {
      getConfigTenant();
    }
  }, [isLogin]);

  const fetchDataMembership = async () => {
    if (tenantProfile?.pointConfiguration?.isEnabled) {
      const point = await getTotalPoints();
      setPoints(point.data.totalPoints);
    }
    if (tenantProfile?.walletConfig?.isEnabled) {
      const balance = await getGuestTotalBalances();
      setWallet(balance.data.total_balances);
    }
    if (tenantProfile?.membershipConfig?.isEnabled) {
      const membership = await getGuestMembershipByGroupId(
        dataLogin?.memberships?.[0]
      );
      setMembershipData(membership.data);
    }
    const voucher = await getGuestVoucher({
      page: "1",
      per_page: "100",
    });
    setTotalVoucher(voucher.meta.total_data);
  };

  useEffect(() => {
    if (tenantProfile.timezone && isLogin) {
      fetchDataMembership();
    }
  }, [tenantProfile]);

  const fetchListCity = async (search?: string) => {
    try {
      const response = await getListCity("", "", search);
      setListCity(response.data);
    } catch (error) {}
  };

  const fetchListBanner = async () => {
    try {
      const response = await getBanners();
      setListBanner(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchListCity();
    fetchListBanner();
  }, []);

  const delayedQuery = useCallback(
    _.debounce((value) => {
      fetchListCity(value);
    }, 500),
    []
  );

  const onTypeSearch = (value) => {
    delayedQuery(value);
  };

  const handleSearch = (city) => {
    if (certainHotel?.length) {
      Router.push(
        `/hotels/rooms/search-rooms?checkin=${dayjs(dateCheckin.in).format(
          "YYYY-MM-DD"
        )}&checkout=${dayjs(dateCheckin.out).format("YYYY-MM-DD")}`
      );
    } else {
      Router.push(
        `/hotels/rooms/search-rooms?cityId=${city?.id}&checkin=${dayjs(
          dateCheckin.in
        ).format("YYYY-MM-DD")}&checkout=${dayjs(dateCheckin.out).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  };

  const handleShowDialogAuth = () => {
    if (!isLogin) {
      setOpenDialogAuth(!openDialogAuth);
    } else {
      setOpenDialogProfile(!openDialogProfile);
    }
  };

  const handleShowDialogLogout = () => {
    setOpenConfirmationLogout(!openConfirmationLogout);
  };

  const handleShowDialogChangeLanguage = () => {
    setOpenDialogChangeLanguage(!openDialogChangeLanguage);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLogin(false);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    setIsUpdateLanguage(true);
  };

  useEffect(() => {
    if (isUpdateLanguage) setTimeout(() => window.location.reload(), 100);
  }, [isUpdateLanguage]);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchListImages = async () => {
    setIsLoading(true);
    try {
      const res = await getListHotels(
        version,
        cityId,
        dayjs(dateCheckin.in).format("YYYY-MM-DD"),
        dayjs(dateCheckin.out).format("YYYY-MM-DD"),
        "hotel_priority",
        "asc"
      );
      const filteredHotel = res.data.filter((item) =>
        certainHotel?.includes(item.hotelId)
      );
      setIsLoading(false);
      const hotelData = certainHotel?.length ? filteredHotel : res.data;
      const allImageUrls = hotelData.flatMap(
        (hotel) => hotel.hotelImageUrls[0]
      );
      const filteredImages = allImageUrls.filter(
        (image) => image !== undefined
      );
      setHotelImages(filteredImages);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (version) {
      if (dateCheckin.in && dateCheckin.out) {
        fetchListImages();
      }
    }
  }, [dateCheckin, version]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <Navbar
        t={t}
        onClickAuth={handleShowDialogAuth}
        isLogin={isLogin}
        onClickOrderRoom={scrollToTarget}
      />
      <Box className={classes.body}>
        <Box className={classes.hero}>
          <Box>
            <Typography className={classes.hotelName} variant="h1">
              {process.env.TENANT_NAME || "Pesan.io"}
            </Typography>
            <Typography variant="body1">
              {process.env.TENANT_DESCRIPTION}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              className={classes.bookNowButton}
              onClick={scrollToTarget}
            >
              {t("book-now")}
            </Button>
          </Box>
          <Box className={classes.hotelImageWrapper}>
            <ImageSlider listImage={hotelImages} />
          </Box>
        </Box>
        <div className={classes.selectCheckinCheckoutWrapper} ref={targetRef}>
          <SelectCheckinCheckout
            t={t}
            handleSearchCity={onTypeSearch}
            listCity={listCity}
            dateCheckin={dateCheckin}
            handleUpdateCheckin={(date) =>
              setDateCheckin({
                ...dateCheckin,
                in: date,
                out: nextDayDate(date),
              })
            }
            handleUpdateCheckout={(date) =>
              setDateCheckin({ ...dateCheckin, out: date })
            }
            handleSearch={handleSearch}
          />
        </div>
        {listBanner.length ? (
          <Box mt="80px">
            <Typography variant="h2" className={classes.titleListBanner}>
              {t("offers-for-you")}
            </Typography>
            <Box className={classes.listBanner}>
              <Grid container spacing={6}>
                {listBanner.map((banner) => (
                  <Grid item xl={4} lg={6} sm={12} key={banner.id}>
                    <CardBanner banner={banner} t={t} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : null}
      </Box>
      <DialogAuth
        t={t}
        open={openDialogAuth}
        onClose={handleShowDialogAuth}
        handleSetLogin={() => setIsLogin(true)}
      />
      <DialogProfile
        open={openDialogProfile}
        t={t}
        onClose={handleShowDialogAuth}
        dataLogin={dataLogin}
        wallet={wallet}
        points={points}
        totalVoucher={totalVoucher}
        membershipData={membershipData}
        tenantProfile={tenantProfile}
        handleShowDialogLogout={handleShowDialogLogout}
        handleShowDialogChangeLanguage={handleShowDialogChangeLanguage}
      />
      <DialogConfirmLogout
        onClose={handleShowDialogLogout}
        handleLogout={() => {
          handleLogout();
          handleShowDialogAuth();
          handleShowDialogLogout();
        }}
        open={openConfirmationLogout}
        t={t}
      />
      <DialogChangeLanguage
        open={openDialogChangeLanguage}
        onClose={handleShowDialogChangeLanguage}
        t={t}
        handleConfirm={(value) => {
          handleChangeLanguage(value);
          handleShowDialogChangeLanguage();
        }}
      />
      <Footer
        t={t}
        onClickAuth={handleShowDialogAuth}
        isLogin={isLogin}
        onClickOrderRoom={scrollToTarget}
      />
      <Loading t={t} open={isLoading} onClose={() => setIsLoading(false)} />
    </Container>
  );
}

Home.getInitialProps = async () => ({
  namespacesRequired: ["common"],
});

export default withTranslation("common")(Home);
