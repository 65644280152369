import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      minHeight: "100vh",
      backgroundColor: "#fafafa",
    },
    body: {
      maxWidth: "80vw",
      width: "100%",
      margin: "auto",
      paddingBottom: 48,
      [theme.breakpoints.down(460)]: {
        maxWidth: "90vw",
      },
    },
    hero: {
      padding: "120px 0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 48,
      [theme.breakpoints.down(1024)]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    hotelName: {
      marginBottom: 16,
    },
    hotelImageWrapper: {
      width: "60%",
      [theme.breakpoints.up(1440)]: {
        width: "50%",
      },
      [theme.breakpoints.down(1024)]: {
        width: "100%",
        order: -1,
      },
    },
    hotelImage: {
      width: "100%",
      height: 400,
      objectFit: "cover",
      borderRadius: 16,
    },
    bookNowButton: {
      borderRadius: 100,
      padding: "16px 32px",
      fontWeight: 700,
      fontSize: 16,
      marginTop: 16,
      height: 47,
    },
    datePickerWrapper: {
      maxWidth: 1400,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      [theme.breakpoints.down(1600)]: {
        maxWidth: 1340,
      },
      [theme.breakpoints.down(1400)]: {
        maxWidth: 1124,
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: 920,
      },
      [theme.breakpoints.down(992)]: {
        maxWidth: 720,
      },
      [theme.breakpoints.down(768)]: {
        maxWidth: 520,
      },
      [theme.breakpoints.down(576)]: {
        maxWidth: 420,
      },
      [theme.breakpoints.down(460)]: {
        width: "100%",
        maxWidth: "unset",
        padding: "0px 15px",
      },
    },
    titleListBanner: {
      marginBottom: 16,
    },
    listBanner: {
      display: "grid",
      gap: 48,
    },
    selectCheckinCheckoutWrapper: {
      backgroundColor: theme.palette.primary.light,
      padding: 48,
      borderRadius: 24,
      [theme.breakpoints.down(426)]: {
        padding: 32,
      },
    },
  })
);

export default useStyles;
