import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    cardBanner: {
      borderRadius: 16,
      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.15)",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    bannerDescription: {
      display: "-webkit-box",
      margin: "16px 0px",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "& p": {
        margin: 0,
      },
    },
    buttonReadMore: {
      width: "100%",
      textAlign: "right",
      textDecorationLine: "underline",
      cursor: "pointer",
      margin: "8px 0px",
      fontWeight: 700,
    },
    cardContent: {
      padding: "16px 24px",
      display: "flex",
      flexDirection: "column",
      height: 200,
    },
    topContent: {
      flex: "1 1",
    },
  })
);

export default useStyles;
