import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import { i18n } from "../../../i18n";
import useStyles from "../../../styles/components/Dialog/ChangeLanguage";

type DialogChangeLanguageProps = {
  readonly t: TFunction;
  open: boolean;
  onClose: () => void;
  handleConfirm: (value: string) => void;
};

const DialogChangeLanguage = (props: DialogChangeLanguageProps) => {
  const { t, open, onClose, handleConfirm } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState("id");

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <Box className={classes.dialogHeader}>
        <Typography variant="h2">{t("logout")}</Typography>
        <Close onClick={onClose} className={classes.closeIcon} />
      </Box>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="language"
          name="language"
          value={language}
          onChange={handleChange}
        >
          <FormControlLabel
            value="id"
            control={<Radio color="primary" />}
            label="Indonesia (ID)"
          />
          <FormControlLabel
            value="en"
            control={<Radio color="primary" />}
            label="English (EN)"
          />
        </RadioGroup>
      </FormControl>
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.cancelButton}
          disableElevation
          variant="outlined"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className={classes.orderButton}
          onClick={() => handleConfirm(language)}
        >
          {t("change")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogChangeLanguage;
