import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerImage: {
      width: "100%",
      height: 400,
      objectFit: "cover",
      borderRadius: 16,
    },
    imageIndex: {
      position: "absolute",
      right: 0,
      bottom: 0,
      marginRight: 10,
      marginBottom: 12,
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      backgroundColor: "rgb(0 0 0 / 0.8)",
      borderRadius: 5,
    },
    dot: {
      backgroundColor: "#FAFAFA",
      height: 6,
      width: 6,
      borderRadius: 6,
      margin: 3,
    },
    dotActive: {
      backgroundColor: theme.palette.primary.main,
      height: 6,
      width: 12,
      borderRadius: 6,
      margin: 3,
    },
    paginationImg: {
      position: "absolute",
      bottom: 0,
      left: 0,
      display: "flex",
      marginBottom: 12,
      marginLeft: 10,
    },
  })
);

export default useStyles;
