import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 24,
    },
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        width: 420,
        padding: 24,
        borderRadius: 16,
        "@media (max-width:425px)": {
          width: "90vw",
        },
      },
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      gap: 16,
      marginTop: 24,
    },
    orderButton: {
      borderRadius: 100,
      padding: "16px 0px",
      fontWeight: 700,
      fontSize: 16,
      width: "50%",
      height: 47,
    },
    cancelButton: {
      fontWeight: 700,
      borderRadius: 100,
      color: "black",
      border: "1px solid #929292",
      width: "50%",
      padding: "16px 0px",
      fontSize: 16,
      height: 47,
    },
    closeIcon: {
      cursor: "pointer",
    },
  })
);

export default useStyles;
