import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    accountInfoWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    closeIcon: {
      cursor: "pointer",
    },
    userName: {
      fontFamily: "Open Sans",
    },
    titleSection: {
      fontSize: 14,
      fontWeight: 700,
      color: "#929292",
    },
    dataWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    dialogProfile: {
      "& .MuiDialog-paperWidthSm": {
        width: 420,
        padding: 24,
        borderRadius: 16,
        "@media (max-width:425px)": {
          width: "90vw",
        },
      },
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 24,
    },
    language: {
      color: "#929292",
    },
    dashedDivider: {
      borderTop: "1px dashed #C9C9C8",
    },
  })
);

export default useStyles;
